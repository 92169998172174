<template>
    <div class="section section--products home_popular_product_block nd-popular-card">
        <div class="container container-nd">
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>{{ title }}</h2>
                    </div>
                </div>
            </div>
            <div class="popular_swiper popular_product_swiper">
                <swiper 
                    :options="swiperOption"
                    :breakpoints="swiperOption.breakpoints"
                    ref="swiperTopPopular"
                    @slideChange="slideChanged"
                >
                    <swiper-slide v-for="(product) in products" :key="product.id"> 
                        <single-card  :product="product" :type="'slider'" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" @click="toSlide('prev')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#CCCCCC" width="17" height="32" viewBox="0 0 8 8">
                    <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z"/>
                    </svg>
                    <span class="sr-only">Предыдущий</span>
                </div>
                <div class="swiper-button-next" @click="toSlide('next')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#CCCCCC" width="17" height="32" viewBox="0 0 8 8">
                    <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z"/>
                    </svg>
                    <span class="sr-only">Следующий</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

import singleCard from '../single-card-slider.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
export default {
    props: {
        title: {
            type: String,
            default: 'Популярные товары',
        },
    },
    mixins: [Functions],
    components: {
        Swiper,
        SwiperSlide,
        singleCard,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOption: {
                spaceBetween: 0,
                breakpoints: {
                    768: {
                        slidesPerView: 3.1
                    },
                    1260: {
                        slidesPerView: 4.1
                    },
                    1680: {
                        slidesPerView: 4.1
                    },
                    1770: {
                        slidesPerView: 4.1
                    },
                    2000: {
                        slidesPerView: 3.1
                    },
                },
            },
            products: [],
            numClickSlide: 0,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            currentWindowWidth: 'app/currentWindowWidth',
            region:'auth/city',
            popularProducts: 'ui_products/products',
        }),
        categoryID1cs() {
          let category_id1cs = []
          if (this.popularProducts && this.popularProducts.length != 0) {
              this.popularProducts.map((element) => {
                  category_id1cs.push(element.id_1c)
              })
          }
          return category_id1cs
        },
    },
    watch: {
        categoryID1cs() {
            this.getCategories() 
        },
        region() {
            this.getCategories()
        },
    },
    mounted() {
        if (this.categoryID1cs.length != 0) {
            this.getCategories()
        }
        this.getPopularProducts()
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
            getPopularProducts: 'ui_products/getProducts',
        }),
        getCategories() {
          this.searchNavbar({id_1cs: this.categoryID1cs, root_id: this.region, componentFrom: 'home/popular'})
              .then((response) => {
                  this.products = response.data
              })
        },
        toSlide(string) {
            if (string == 'next') {
                if (this.products.length == this.numClickSlide) {
                    return
                }
                this.numClickSlide += 1
            }else if (string == 'prev') {
                if (this.numClickSlide == 0) {
                    return
                }
                this.numClickSlide -= 1
            }

            this.$refs.swiperTopPopular.$swiper.slideTo(this.numClickSlide, 0)
        },
        slideChanged() {
            this.numClickSlide = 0
        },  
    },
}
</script>
<style>
    .swiper-container{
        padding: 10px;
    }
</style>
<style scoped src="../../../../css/castom.css"></style>
<style lang="scss">
    .popular_product_swiper{
        .swiper-button-prev{
            left: -40px;
        }

        .swiper-button-next{
            right: -40px;
        }
    }
</style>