<template>
    <section class="about_company-info_page-sp_advantages profile_advantages">
        <div class="container container-nd">
            <p class="main_header">Преимущества</p>
            <div class="main_block">
                <div class="left_part">
                    <ul class="list-advantage">
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 1 }">
                            <div class="advantage_icon-div" />
                            <p @click="setNumActiveAdvantage(1)">Широкий ассортимент профилей</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 2 }">
                            <div class="advantage_icon-div" />
                            <p @click="setNumActiveAdvantage(2)">Широкая палитра цветов</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 3 }">
                            <div class="advantage_icon-div" />
                            <p @click="setNumActiveAdvantage(3)">Долговечность</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 4 }">
                            <div class="advantage_icon-div" />
                            <p @click="setNumActiveAdvantage(4)">Надежность</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 5 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/8.png" alt="">
                            <p @click="setNumActiveAdvantage(5)">Легкость монтажа</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 6 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/6.png" alt="">
                            <p @click="setNumActiveAdvantage(6)">Экологичность</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 7 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/1.png" alt="">
                            <p @click="setNumActiveAdvantage(7)">Пожаробезопасность</p> 
                        </li>
                    </ul>
                </div>
                <div class="right_part">
                    <img class="main_img" src="@/img/info-sp/advantage2.png" alt="advantage main">
                    <div v-if="numActiveAdvantage != 0" class="text_block">
                        <p class="mt-40">
                            Компания МаякМеталл производит профлист разных видов и размеров, что позволяет 
                            выбрать наиболее подходящий вариант для конкретных потребностей.
                        </p>
                        <p class="advantage_header">- Что из этого следует?</p>
                        <p>
                            Благодаря разнообразию профилей, профлист можно использовать для различных 
                            конструкций: кровельных покрытий, стен, заборов, ограждений и др.
                        </p>
                        <p class="advantage_header">- Какая от этого выгода?</p>
                        <p>
                            Широкий ассортимент профилей позволяет подобрать оптимальное решение, которое будет 
                            соответствовать требованиям и внешнему виду объекта, обеспечивая 
                            эстетичность и функциональность.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            numActiveAdvantage: 0,
        }
    },
    methods: {
        setNumActiveAdvantage(num) {
            if (num == this.numActiveAdvantage) {
                this.numActiveAdvantage = 0
            }else{
                this.numActiveAdvantage = num
            }
        },
    },
}
</script>