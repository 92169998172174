<template>
    <section>
        <div class="nd-about-company_page nd_single_news_page">
            <div v-if="isShowBreadcrumbs" class="container container-nd">
                <bread-crumbs class="breadcrumbs" :pages-array="pages" :withNdProp="false" />
            </div>
            <div class="container container-nd">
                <div class="single_main_block">
                    <img :src="currentNews.image" alt="">

                    <p class="single_news_header">{{ currentNews.name }}</p>
                    <div class="single_news_description" v-html="currentNews.description"></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'

export default {
    components: {
        breadCrumbs,
    },
    data() {
        return {
            pages: [
                {id: 0, name: "О компании", url: "aboutcompany", fullUrl: 'aboutcompany'},
                {id: 10, name: "Новости", url: "aboutcompany", fullUrl: 'aboutcompany/news'},
            ],
            isShowBreadcrumbs: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            currentNews: 'ui_news/currentNews',
        }),
        singleNewsId() {
            return this.$route.params.id
        },
    },
    mounted() {
        this.getOne(this.singleNewsId)
            .then(() => {
                this.addLinkPage()
            })
    },
    methods: {
        ...Vuex.mapActions({
            getOne: 'ui_news/getOne',
        }),
        addLinkPage() {
            this.pages.push({
                id: 20,
                name: this.currentNews.name,
                url: 'single',
                fullUrl: 'aboutcompany/single/' + this.currentNews.id
            })
            this.isShowBreadcrumbs = true
        },
    },
}
</script>
<style lang="scss">
    .nd_single_news_page{
        .single_main_block{
            margin-top: 60px;
            margin-bottom: 250px;

            .single_news_header{
                margin-top: 77px;
                margin-bottom: 60px;
                font-weight: 700;
                font-size: 2.3rem;
                line-height: 2.8rem;  
                color: #353B48;              
            }

            .single_news_description{
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.3rem;
                margin-bottom: 25px;

                p{
                    margin: 0;
                }
            }
        }
    }
</style>